<!-- Alert -->

<template>
  <div class="alert" v-if="snackbar">
    <!-- Fondo pantalla -->
    <v-overlay z-index="999"  :value="snackbar">

      <v-snackbar
        v-model="snackbar"
        v-bind="tipo ? tipo.bind : ''"
        :vertical="tipo.name === 'confirm'">
        <!-- Todos los tipos distintos de confirm -->
        <template v-if="tipo.name !== 'confirm'">

            <div class="conflex" style="justify-content: space-between;align-items:center">
              <div class="columna" style="width:93%">
                <div class="conflex">
                  <v-icon dark>{{ tipo.icon }}</v-icon>
                  <span v-html="mensaje" style="margin-left:10px"></span>
                </div>
              </div>

              <div class="columna" style="width:5%;margin-left:5px">
                <v-btn
                  class="ma-1 black--text"
                  color="white"
                  small
                  icon
                  icono="mdi-close-circle-outline"
                  @click="close">
                    <v-icon dark dense>{{ "mdi-close" }}</v-icon>
                </v-btn>
              </div>
            </div>

        </template>

        <!-- Tipo Confirm -->
        <template v-else>
          <div class="columna">
            <div style="display:flex">
              <v-icon dark class="pr-2">{{ tipo.icon }}</v-icon>
              <!-- <span class="body-1">{{ mensaje }}</span> -->
              <span v-html="mensaje"></span>
            </div>

            <div v-if="btns== null">
              <v-row no-gutters class="justify-center">
                <v-btn
                  class="ma-1 black--text"
                  color="white"
                  x-small
                  icono="mdi-close-circle-outline"
                  @click.native="agree"
                >
                  <v-icon small>{{ "mdi-check-circle-outline" }}</v-icon>
                  Aceptar
                </v-btn>

                <v-btn
                  class="ma-1 black--text"
                  color="white"
                  x-small
                  icono="mdi-close-circle-outline"
                  @click.native="cancel"
                >
                  <v-icon small>{{ "mdi-close-circle-outline" }}</v-icon>
                  Cancelar
                </v-btn>
              </v-row>
            </div>

            <div v-else style="display:flex;width:500px;justify-content:center;">
              <template v-for="(btn, index) in btns">
                <v-btn
                  :key="index"
                  class="ma-1 black--text"
                  color="white"
                  x-small
                  icono="mdi-close-circle-outline"
                  @click.native="agreeBtns(index)">
                    {{ btn }}
                </v-btn>
              </template>

            </div>
          </div>
        </template>
      </v-snackbar>

    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false,
      mensaje: "",
      tipo: null,
      btns: null,
      tipos: [
        {
          name: "success",
          icon: "mdi-check-circle-outline",
          bind: { color: "green", top: true }
        },
        {
          name: "info",
          icon: "mdi-information-outline",
          bind: { color: "orange", top: true }
        },
        {
          name: "error",
          icon: "mdi-alert-circle-outline",
          bind: { color: "red", top: true }
        },
        {
          name: "confirm",
          icon: "mdi-help-circle-outline",
          bind: { color: "cyan darken-2", top: true }
        },
        {
          name: "default",
          icon: "mdi-alert-decagram-outline",
          bind: { color: "cyan darken-2", top: true }
        }
      ],
      resolve: null,
      reject: null
    };
  },

  methods: {
    // muestro notificación
    open(mensaje, tipo = "default", timeout = null, btns= null, bind = null) {
      // salgo si no he recibido un mensaje a mostrar
      if (!mensaje) return;
      this.mensaje = mensaje;

      // establezo la configuración de v-bind
      if (bind) {
        this.tipo = this.tipos[4];
        this.tipo.bind = bind;
      } else {
        const elem = this.tipos.find(elem => elem.name === tipo);
        this.tipo = elem === undefined ? this.tipos[4] : elem;
      }

      // muestro la notificación


      // array de botones en un confirm
      this.btns= btns;


      // temporizador o indifinido
      timeout ? setTimeout(this.close, timeout) : (this.tipo.bind.timeout = 0);

      // devuelve promesa
      return new Promise((resolve, reject)  => {
        this.snackbar = true;
        this.$watch('snackbar', {
          immediate: true,
          handler() {
            if (this.snackbar=== false) resolve();
            if (this.snackbar=== 'rechazada') reject();
          }
        });
      });
    },

    // acepto promesa
    agree() {
      /* this.resolve(true); */
      this.snackbar = false;
    },

    // acepto promesa
    agreeBtns() {
      /* this.resolve(r); */
      this.snackbar = false;
    },

    // NO acepto promesa
    cancel() {
      /* this.resolve(false); */
      this.snackbar = 'rechazada';
    },

    // cierro notificación
    close() {
      /* this.resolve(true); */
      this.snackbar = false;
    }
  }
};
</script>
